$(document).ready(function () {
    $('#rootwizard').bootstrapWizard({
        onTabShow: function (tab, navigation, index) {
            console.log(tab);
            var $total = navigation.find('li').length;
            var $totalNumber = navigation.find('li').length - 1;
            var $current = index;
            if ($current == -1) {
                $current = 0;
            }
            // change images related to index
            document.getElementById('survey-image').src = "https://snipclip-website.staging.dept42.de/wp-content/themes/snipclip/src/imgs/survey/Question" + ($current+1) + ".png";

            console.log($current);
            $('#current').text($current);
            $('#total').text($totalNumber);

            var $percent = ($current / $total) * 125;
            $('#rootwizard .progress-bar').css({ width: $percent + '%' });
        }
    });

    $("#startbtn").click(function() {
        $("#startpage").hide();
        $("#rootwizard").css("display", "flex");
    });

});