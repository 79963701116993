import wow from './vendor/wow.min'
window.WOW = wow;

var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
// make Isotope a jQuery plugin
jQueryBridget( 'isotope', Isotope, $ );
require('isotope-packery');
window.isotope = Isotope;

	function createCookie(name, value, hours) {
		if (hours) {
			var date = new Date();
			date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
			var expires = "; expires=" + date.toGMTString();
		} else var expires = "";
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	function readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	if ($("body").hasClass("page-template-page-index")) {
		if (readCookie("animation") != 1) {
			// show animation
			$("#start-page").show();
			$(".header-slider-text").addClass("animation");
			$(".page-template-page-index").parents().css("overflow", "hidden");
			$('html, body').css("touch-action", "none");
			// delay cookie notice
			$("#wrapper").on('DOMNodeInserted', function(e) {
				if ($("#cookie-notice").hasClass('cookie-notice-visible')) {
					setTimeout(function () {
						$("#cookie-notice").fadeIn();
					}, 7000);
				}
			});
			// remove animation
			setTimeout(function () {
				$("#start-page .header-slider-left").addClass("animated fadeOutLeft slower");
				$("#start-page .header-img").addClass("animated fadeOut");
				$(".header-slider-text").addClass("animated slideInUp");
				$('.header-text-one').hide();
				$(".header-text-two").removeClass("delay-5s");
				$(".header-text-two").addClass("color-change");
				$('.header-text-cs-one').hide();
				$('.header-text-cs-two').hide();
				$(".header-text-cs-three").addClass("color-change-cs");
				$(".page-template-page-index").parents().css("overflow", "initial");
				$('html, body').css("touch-action", "auto");
				$(".page-template-page-index").css("overflow", "initial");
				createCookie("animation", 1, 3);
			}, 5000);
		} else {
			$("#start-page").hide();
			$(".services-section").removeClass("delay-7s");
			$("#animated-navbar").removeClass("delay-7s");
			$("#filter-cards").removeClass("delay-7s");
			$(".header-slider-text").css("z-index", "1");
			$('.header-text-one').hide();
			$(".header-text-two").removeClass("fadeOut delay-5s");
			$(".header-text-two").addClass("color-change");
			$("#cookie-notice.cookie-notice-visible").fadeIn();
			// show cookie notice
			$("#wrapper").on('DOMNodeInserted', function(e) {
				if ($("#cookie-notice").hasClass('cookie-notice-visible')) {
					$("#cookie-notice").fadeIn();
				}
			});
		}
	} else {
		$("#cookie-notice.cookie-notice-visible").fadeIn();
		// show cookie notice
		$("#wrapper").on('DOMNodeInserted', function(e) {
			if ($("#cookie-notice").hasClass('cookie-notice-visible')) {
				$("#cookie-notice").fadeIn();
			}
		});
	}

	$(function () {

		// remove all empty p tags
		$('p:empty').remove();

		// add opt-out function to link
		$('*[data-name="opt-out"]').attr("href", "javascript:gaOptout()");

		let footerHeight = $('#footer').outerHeight();
		$('#container').css('padding-bottom', footerHeight);

		var deviceAgent = navigator.userAgent.toLowerCase();
		if (deviceAgent.match(/(iphone|ipod|ipad)/)) {
			$("html").addClass("ios");
			$("html").addClass("mobile");
		}
		if (navigator.userAgent.search("MSIE") >= 0) {
			$("html").addClass("ie");
		} else if (navigator.userAgent.search("Chrome") >= 0) {
			$("html").addClass("chrome");
		} else if (navigator.userAgent.search("Firefox") >= 0) {
			$("html").addClass("firefox");
		} else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
			$("html").addClass("safari");
		} else if (navigator.userAgent.search("Opera") >= 0) {
			$("html").addClass("opera");
		}

		//------------------------------------------------------------------------------
		// Change infinite scroll button
		//------------------------------------------------------------------------------

		var skeleton = "<div class='skeleton'> <div class='row mb-40'> <div class='col-md-3'> <div class='avatar blog'></div> </div> <div class='col-md-9 col-xl-7 pl-md-3 pt-3 pt-md-0'> <div class='row'> <div class='col-9'> <h4 class='line'></h4> </div> </div> <div class='row'> <div class='col-3'> <p class='line small-text mb-20'></p> </div> </div> <div class='row'> <div class='col-12 mb-20'> <p class='line'></p> <p class='line'></p> <p class='line'></p> </div> </div> <div class='row'> <div class='col-2 '> <p class='line '></p> </div> </div> </div> </div> </div>";

		$(".alm-placeholder").html(skeleton);

		$(".alm-btn-wrap").hide();

		//------------------------------------------------------------------------------
		// Carousel fix
		//------------------------------------------------------------------------------

		$(function () {
			return $(".carousel").on("slide.bs.carousel", function (ev) {
				$(".carousel-item img").trigger("unveil");
			});
		});

		//------------------------------------------------------------------------------
		// animate.css with wow.js
		//------------------------------------------------------------------------------

		// animate functions: https://daneden.github.io/animate.css/

		$('.btn').not('form .btn.dropdown-toggle, .sidebar-toolbox .btn, .modal .btn').each(function () {
			$(this).addClass("wow fadeInDown");
		});

		$('.btn').not('form .btn.dropdown-toggle').on('mouseenter', function () {
			$(this).addClass("animated pulse");
		}).on('mouseleave', function () {
			$(this).removeClass("animated pulse");
		});

		var afterReveal = function (e) {
			var $ele = $(e);
			$ele.addClass("foo");
		}

		var wow = new WOW({
			callback: afterReveal
		});

		wow.init();

		//------------------------------------------------------------------------------
		// Logo carousel
		//------------------------------------------------------------------------------
		var logos = $('.clients-logos .logo-item').length;
		var logoNumber = 8;

		if (logos && logos < 8) {
			logoNumber = logos;
		}

		$('.clients-logos').slick({
			slidesToShow: logoNumber,
			slidesToScroll: logoNumber,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 2000,
			cssEase: 'ease-out',
			arrows: false,
			dots: false,
			pauseOnHover: true,
			responsive: [{
				breakpoint: 1800,
				settings: {
					slidesToShow: logoNumber - 2,
					slidesToScroll: logoNumber - 2
				}
			}, {
				breakpoint: 1200,
				settings: {
					slidesToShow: logoNumber - 3,
					slidesToScroll: logoNumber - 3
				}
			}, {
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			}, {
				breakpoint: 520,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}]
		});

		//------------------------------------------------------------------------------
		// Filter items
		//-----------------------------------------------------------------------------
		$('.filter-sidebar.mobile ul li a').click(function () {
			$(".filter-sidebar ul li a").removeClass("active");
			$(this).addClass("active");
			$(".filter-sidebar ul").scrollCenter(".active", 300);
		});

		//------------------------------------------------------------------------------
		// Image slider
		//-----------------------------------------------------------------------------
		let i = 1;
		if (readCookie("index") != undefined) {
			i = Number(readCookie("index")) + 1;
		}
		$(".image-list .image-item").removeClass("active");

		$(window).on('load', function () {
			$(".image-list > li:nth-child(" + i + ")").addClass('active').trigger("click");
		});

		$('.image-list').on('click', '.image-item', function () {
			$(".image-list .image-item").removeClass("active");
			$(this).addClass("active");
			var index = $(this).index();
			createCookie("index", index);
			$(".image-list").scrollCenter(".active", 300);

			$("#referenceItem .reference-slider").css("visibility", "hidden");
			$("#referenceItem .reference-text").css("visibility", "hidden");

			var skeleton = "<div class='container full-width mb-40'><div class='skeleton'><div class='avatar mb-30 animated fadeIn'></div><div class='container animated slideInUp fast'> <div class='row'> <div class='col-8'> <div class='line'></div></div></div><div class='row'> <div class='col-2'> <div class='line'></div></div></div><div class='row pt-20'> <div class='col-12 col-md-9'> <p class='line'></p><p class='line'></p><p class='line'></p><p class='line'></p></div><div class='col-12 col-md-2 ml-auto'> <p class='line mb-20'></p><p class='line mb-20'></p><p class='line mb-20'></p><p class='line'></p></div></div></div></div></div>";

			$("#referenceItem").html(skeleton);

			setTimeout(function () {
				$("#referenceItem").load(location.href + " #referenceItem>*", function () {

					//initLightSlider();

					$('p:empty').remove();

					$("#referenceItem .reference-text").addClass("animated fadeIn");
					$("#referenceItem .reference-text").css("visibility", "visible");

					$("#referenceItem .reference-slider").addClass("animated fadeIn");
					$("#referenceItem .reference-slider").css("visibility", "visible");
				});
			}, 1500);
		});

		$('.filter-link.reference').on('click', function () {
			var refLinks = $(".filter-link.reference");
			var refIndex = refLinks.index($(this));
			createCookie("index", refIndex);
		});

		// scrollCenter function
		jQuery.fn.scrollCenter = function (elem, speed) {
			var active = $(this).find(elem); // find the active element
			var activeWidth = active.width() / 2; // get active width center

			var pos = active.position().left + activeWidth; // get left position of active element + center position
			var elpos = $(this).scrollLeft(); // get current scroll position
			var elW = $(this).width(); // get container width
			pos = pos + elpos - elW / 2; // for center position

			$(this).animate({
				scrollLeft: pos
			}, speed == undefined ? 1000 : speed);
			return this;
		};

		//------------------------------------------------------------------------------
		// Application form
		//------------------------------------------------------------------------------
		$('#appl-form input').change(function () {
			$('form p').text(this.files.length + " file(s) selected");
		});

		var mobile = $(window).width() < 567;
		$('#formToggle').click(function (e) {
			if (!mobile) {
				e.preventDefault();
				$('.job-description').css('paddingBottom', '0px');

				$('#application-form').slideToggle('slow');

				$("html").stop().animate({
					scrollTop: $('#application-form').offset().top
				}, (400), "linear");

				$('#formToggle').hide();
			} else {
				e.preventDefault();
				$('.job-description').hide();
				$('#application-form').addClass('mobile');
				$('#application-form').show();
				window.scroll(0,0);
				$('#formToggle').hide();
			}
		});

		$('#formToggleBack').click(function (e) {
			e.preventDefault();
			$('.job-description').show();
			$('#application-form').hide();
			window.scroll(0,0);
			$('#formToggle').show();
		});

		//------------------------------------------------------------------------------
		// Sidebar navbar
		//------------------------------------------------------------------------------
		$(document).ready(function () {

			$(".drawer-toggle").on("click", function(e){
				e.preventDefault();
				e.stopPropagation();
				var drawernav_id =  $('.drawer-nav');
				$(drawernav_id).toggleClass("show");
				$('body').toggleClass("drawer-nav-active");
				$(".drawer-screen-overlay").toggleClass("show");
			});

			$(".drawer-screen-overlay").click(function(e){
				$(".drawer-screen-overlay").removeClass("show");
				$(".drawer-nav").removeClass("show");
				$("body").removeClass("drawer-nav-active");
			});

		});

		// Close menu when pressing ESC
		$(document).on('keydown', function(event) {
			if(event.keyCode === 27) {
				$(".drawer-screen-overlay").removeClass("show");
				$(".drawer-nav").removeClass("show");
				$("body").removeClass("drawer-nav-active");
			}
		});


		//------------------------------------------------------------------------------
		// Fixed Logo
		//------------------------------------------------------------------------------
		// Stop the fixed logo from scrolling over the footer: Desktop
		$(document).scroll(function () {
			checkOffset();
		});

		function checkOffset() {
			if ($('.nav-header').offset().top + $('.nav-header').height() >= $('#footer').offset().top)
				$('.nav-header').css('position', 'absolute');

			if ($(document).scrollTop() < $('#footer').offset().top - 50)
				$('.nav-header').css('position', 'fixed'); // restore when you scroll up
		}

		const element = document.querySelector('.header-slider-text.animation')
		if (element) {
			element.addEventListener('animationend', function () {

				removeAnimation();

				$('.header-slider-text').css({
					zIndex: 10,
				});
			});
		}

		function removeAnimation() {
			setTimeout(function () {
				$("#start-page").hide();
			}, 2000);
		}

		//------------------------------------------------------------------------------
		// Footer
		//------------------------------------------------------------------------------
		function isVisible(object) {
			let viewport = $(window).scrollTop() + $(window).height();
			let margin = $(object).offset();
			margin.bottom = margin.top + $(object).outerHeight();
			return (!(viewport < (margin.top + 300) || $(window).scrollTop() > (margin.bottom - 300)));
		}

		if ($(window).width() > 768) {
			let lastScrollTop = 0;
			$(window).scroll(function (event) {
				let st = $(this).scrollTop();
				let footer = $('.' + 'hello-footer');
				if (footer.length > 0 && isVisible(footer)) {
					if (st > lastScrollTop) {
						if (!$(footer).hasClass('hello')) {
							let footer2 = $(footer).addClass('hello');
							$('html, body').animate({
								scrollTop: $(footer2).offset().top + $("#header").outerHeight()
							}, 100);
						}
					} else {
						$(footer).removeClass('hello');
					}
				}
				lastScrollTop = st;
				if ($(this).scrollTop() <= 0) {
					$('footer').removeClass('hello');
				};
			});
		}

		//------------------------------------------------------------------------------
		// footer manipulations
		//------------------------------------------------------------------------------
		var footerIntroText = $(".footer-intro-wrapper");
		var footerContactWrapper = $(".footer-contact-wrapper");

		if (footerIntroText.hasClass("d-none")) {
			footerContactWrapper.removeClass("col-lg-5").addClass("col-lg-9 col-md-12");
		}
	});

	//------------------------------------------------------------------------------
	// Filter Cards
	//------------------------------------------------------------------------------
	// filter cards as squares + adjusted font-size *on resize
	$(window).on('resize', function () {
		var filterCardItems = $(".filter-card-item");
		if (filterCardItems.length > 0) {
			filterCardItems.each(function () {
				if ($(this).width() > 0) {
					let filterCardsWidth = $(this).width();
					if ($(window).width() > 576) {
						filterCardItems.css({
							'height': filterCardsWidth + 'px'
						});
					} else {
						filterCardItems.css({
							'height': filterCardsWidth / 1.3 + 'px'
						});
					}
					var fontSize = filterCardsWidth * 0.08;
					var smallText = filterCardsWidth * 0.05;
					$(".filter-card-item-inner h3").css('font-size', fontSize);
					$(".filter-card-item-inner h3").css('line-height', fontSize + 10 + 'px');

					$(".filter-card-item-inner .small-text").css('font-size', smallText);
					$(".filter-card-item-inner .small-text").css('line-height', smallText + 10 + 'px');
				}
			});
		}

		var filterElements = $('.filter-cards .filter-card-col');
		if (filterElements.length > 0) {
			filterElements.each(function () {
				if ($(this).hasClass("Blog double")) {
					var height = ($(this).height() / 4) - 7.5;
					var desktop_large = $(window).width() >= 1900;
					var mobile = $(window).width() < 567;
					if (desktop_large) {
						height = height - 7.5;
					} else if (mobile) {
						height = $(this).width() / 1.5;
					}
					$(this).find(".filter-card-item").height(height);
				}
			})
		}

		if (!$('.filter-cards .row.filter').hasClass('isotope') && $(window).width() > 567) {
			console.log("initi")
			layoutFilterCards('*');
			return false;
		}
	});

	// filter cards layout
	function layoutFilterCards(selector) {
		if (selector == '*') {
			let references = $('.filter-card-col.Referenz');
			let references_en = $('.filter-card-col.Reference');
			if (references.length) {
				references.each(function(index){
					if (index > 4) {
						$(this).addClass("d-none");
					}
				});
			} else if (references_en.length) {
				references_en.each(function(index){
					if (index > 4) {
						$(this).addClass("d-none");
					}
				});
			}
			let blogs = $('.filter-card-col.Blog');
			let max = 4;
			blogs.each(function(index){
				if ($(this).hasClass('double') && index < max-1) {
					max = max - 1;
				}
				if (index > max) {
					$(this).addClass("d-none");
				}
			});
			let jobs = $('.filter-card-col.Job');
			jobs.each(function(index){
				if (index > 4) {
					$(this).addClass("d-none");
				}
			});
		} else {
			$('.filter-card-col'+selector).removeClass("d-none");
		}
		// isotope
		$('.filter-cards .row.filter').ready(function() {
			$('.filter-cards .row.filter').isotope({
				layoutMode: 'packery',
				itemSelector: '.filter-card-col',
				percentPosition: true,
				packery: {
					//gutter: 10
				},
				filter: selector,
				sortBy: 'original-order'
			});
			$('.filter-cards .row.filter').addClass('isotope');
		});
	};

	$(window).on('load', function () {

		// filter cards as squares + adjusted font-size
		var filterCardItems = $(".filter-card-item");
		if (filterCardItems.length > 0) {
			filterCardItems.each(function () {
				if ($(this).width() > 0) {
					let filterCardsWidth = $(this).width();

					if ($(window).width() > 576) {
						filterCardItems.css({
							'height': filterCardsWidth + 'px'
						});
					} else {
						filterCardItems.css({
							'height': filterCardsWidth / 1.4 + 'px'
						});
					}
					var fontSize = filterCardsWidth * 0.08;
					var smallText = filterCardsWidth * 0.05;
					$(".filter-card-item-inner h3").css('font-size', fontSize);
					$(".filter-card-item-inner h3").css('line-height', fontSize + 10 + 'px');

					$(".filter-card-item-inner .small-text").css('font-size', smallText);
					$(".filter-card-item-inner .small-text").css('line-height', smallText + 10 + 'px');
				}
			});
		};

		// blog cards half size
		var filterElements = $('.filter-cards .filter-card-col');
		if (filterElements.length > 0) {
			filterElements.each(function () {
				if ($(this).hasClass("Blog") && $(this).next().hasClass("Blog")) {
					var height = ($(this).height() / 2) - 5;
					var desktop_large = $(window).width() >= 1900;
					var mobile = $(window).width() < 567;
					if (desktop_large) {
						height = height - 5;
					} else if (mobile) {
						height = $(this).width() / 1.5;
					}
					$(this).find(".filter-card-item").height(height);
					$(this).next().find('.filter-card-item').height(height);
					$(this).addClass('double');
					$(this).append($(this).next().find('.filter-link'));
					$(this).next().remove();

					let blogHeight = $(this).find('.filter-card-item').height();
					var fontSize = blogHeight * 0.15;
					var smallText = blogHeight * 0.1;
					$(this).find('.filter-card-item-inner h3').css('font-size', fontSize);
					$(this).find('.filter-card-item-inner h3').css('line-height', fontSize + 5 + 'px');
					$(this).find('.filter-card-item-inner .small-text').css('font-size', smallText);
					$(this).find('.filter-card-item-inner .small-text').css('line-height', smallText + 5 + 'px');
				}
			}).promise().done(function () {
				if ($(window).width() > 576) {
					layoutFilterCards('*')
				} else {
					let references = $('.filter-card-col.Referenz');
					let references_en = $('.filter-card-col.Reference');
					if (references.length) {
						references.each(function(index){
							if (index > 4) {
								$(this).addClass("d-none");
							}
						});
					} else if (references_en.length) {
						references_en.each(function(index){
							if (index > 4) {
								$(this).addClass("d-none");
							}
						});
					}
					let blogs = $('.filter-card-col.Blog');
					let max = 4;
					blogs.each(function(index){
						if ($(this).hasClass('double') && index < max-1) {
							max = max - 1;
						}
						if (index > max) {
							$(this).addClass("d-none");
						}
					});
					let jobs = $('.filter-card-col.Job');
					jobs.each(function(index){
						if (index > 4) {
							$(this).addClass("d-none");
						}
					});
				}
			});
		};

		// remove unused filter sidebar elements
		var filterLinks = $('.filter-sidebar ul li a');
		if (filterLinks.length > 0) {
			filterLinks.each(function () {
				var selector = $(this).attr('data-filter');
				var filterClass = $(this).parents().find(selector);
				if (filterClass.length < 1) {
					$(this).hide();
				}
			});
		}

		// scroll to top of container on filter click
		var $htmlBody = $('html, body');
		$('.filter-sidebar ul li a').click(function () {
			var selector = $(this).attr('data-filter');

			// add active class
			$('.filter-sidebar ul li a').removeClass('active');
			$(this).addClass('active');
			$(".filter-sidebar ul").scrollCenter(".active", 300);

			// scroll to section
			if ($(window).width() > 768) {
				$htmlBody.stop().animate({
					scrollTop: $('.filter-cards').offset().top - 30
				}, 600);
			} else {
				$htmlBody.stop().animate({
					scrollTop: $('.filter-cards').offset().top - 110
				}, 600);
			}

			// if blog
			if (selector == '.Blog') {
				var blogFilterElements = $('.filter-cards .filter .Blog.double');
				var blogHeight = $('.filter-cards .filter .Blog:not(.double)').height();
				if (blogFilterElements.length > 1) {
					blogFilterElements = blogFilterElements.slice(1);
					blogFilterElements.each(function () {
						$(this).find(".filter-card-item").height(blogHeight);
						$(this).removeClass('double');
						$(this).clone().insertAfter($(this));
						$(this).find(".filter-link").eq(1).remove();
						$(this).next().find('.filter-link').first().remove();
					});
					$('.filter-cards .row.filter').isotope( 'reloadItems' ).isotope();
					layoutFilterCards(selector);
				} else {
					layoutFilterCards(selector);
				}
			} else {
				layoutFilterCards(selector);
			}

			// show link
			if (selector == '*') {
				$('.filter-cards-link').removeClass("d-block");
			} else if (selector == '.Reference' || selector == '.Referenz') {
				$('.filter-cards-link').removeClass("d-block");
				$('.filter-cards-link.more-ref').addClass("d-block");
				if (location.href.search("lang=en")!=-1){
					var url = location.href.split('?');
					url.pop();
					url = url[0];
					$(".filter-cards-link.more-ref a").prop("href", url + "references/?lang=en")
				} else {
					$(".filter-cards-link.more-ref a").prop("href", location.href + "references/")
				}
			} else if (selector == '.Job') {
				$('.filter-cards-link').removeClass("d-block");
				$('.filter-cards-link.more-jobs').addClass("d-block");
				if (location.href.search("lang=en")!=-1){
					var url = location.href.split('?');
					url.pop();
					url = url[0];
					$(".filter-cards-link.more-jobs a").prop("href", url + "jobs/?lang=en")
				} else {
					$(".filter-cards-link.more-jobs a").prop("href", location.href + "jobs/")
				}
			} else if (selector == '.Blog') {
				$('.filter-cards-link').removeClass("d-block");
				$('.filter-cards-link.more-blogs').addClass("d-block");
				if (location.href.search("lang=en")!=-1){
					var url = location.href.split('?');
					url.pop();
					url = url[0];
					$(".filter-cards-link.more-blogs a").prop("href", url + "blog/?lang=en")
				} else {
					$(".filter-cards-link.more-blogs a").prop("href", location.href + "blog/")
				}
			}
			return false;
		});
	});

	$(window).on('load resize', function () {
		if ($(window).width() <= 768) {
			/* change text of language sitcher in mobile */
			$(".language-switcher ul li a span[lang=de]").text("DE");
			$(".language-switcher ul li a span[lang=en]").text("EN");
		} else {
			/* change text of language sitcher in mobile */
			$(".language-switcher ul li a span[lang=de]").text("Deutsch");
			$(".language-switcher ul li a span[lang=en]").text("English");
		}
		// add footer space to wrapper element
		let footerHeight = $('#footer').outerHeight();
		$('#container').css('padding-bottom', footerHeight);
	});

	//------------------------------------------------------------------------------
	// Keep Footer Link Aktuelles in viewport
	//------------------------------------------------------------------------------

	$(window).on('load resize', function () {

		let heroText = $(".header-slider-text");
		let serviceSection = $(".services-section");

		let startSection = $(".start-section");

		if (startSection.length > 0) {

			// set back vh sizes
			heroText.css("height", "auto");
			serviceSection.css("height", "auto");

			startSection.css("height", "100%");

			// header
			let headerHeight = $("#header").outerHeight();
			// start section
			let startSectionHeight = $(".start-section").outerHeight();
			// filter card col
			let filterCardColHeight = $(".filter-cards .filter-card-col").outerHeight();

			// viewport
			let viewportHeight = $(window).innerHeight();
			// content
			let contentHeight = parseInt(headerHeight) + parseInt(startSectionHeight) + parseInt(filterCardColHeight);

			if (viewportHeight > contentHeight || $(window).width() < 992) {
				$(".services-section .page-footer-link").css("opacity", "0");
				$(".filter-cards-headline.desktop").show();
				serviceSection.css("height", "auto");
				window.removeEventListener('wheel', checkScrollPosition);
			} else {
				$(".services-section .page-footer-link").css("opacity", "1");
				setTimeout(function () {
					checkScrollPosition();
				}, 6000);
				startSection.css("height", "calc(100vh - " + headerHeight + "px)");
				$(".filter-cards-headline.desktop").hide();
			}
		}
	});


	//------------------------------------------------------------------------------
	// Scroll to section (startpage)
	//------------------------------------------------------------------------------
	function checkScrollPosition() {
		var scrollStatus = {
			wheeling: false,
			functionCall: false
		};
		var scrollTimer = false;
		window.addEventListener('wheel', function (e) {
			scrollStatus.wheeling = true;
			if (!scrollStatus.functionCall) {
				//parseScroll here
				if (e.deltaY > 0) {
					scrollDownAnimation();
				}
				if (e.deltaY < 0) {
					scrollUpAnimation();
				}
				scrollStatus.functionCall = true;
			}
			window.clearInterval(scrollTimer);
			scrollTimer = window.setTimeout(function () {
				scrollStatus.wheeling = false;
				scrollStatus.functionCall = false;
			}, 150); //set this millisecond to your liking
		});
	}

	function scrollDownAnimation() {
		if ($(window).scrollTop() < $('#filter-cards').offset().top) {
			$("html").stop().animate({
				scrollTop: $('#filter-cards').offset().top
			}, (600), "linear");
		}
	}

	function scrollUpAnimation() {
		if ($(window).scrollTop() < $('#filter-cards').offset().top + 50) {
			$("html").stop().animate({
				scrollTop: $('#start-page').offset().top
			}, (600), "linear");
		}
	}

	$('.services-section .page-footer-link a').click(function (e) {
		e.preventDefault();
		scrollDownAnimation();
		return false;
	});


	//------------------------------------------------------------------------------
	// wpcf7 customizations
	//------------------------------------------------------------------------------
	$(".wpcf7-submit").after("<span class='underling'></span>");
	$(".wpcf7-submit").parent().addClass('page-footer-link');


	document.addEventListener( 'wpcf7mailsent', function( event ) {
		setTimeout(function () {
		  $(".wpcf7-mail-sent-ok").addClass('alert fade show');
		  $(".wpcf7-mail-sent-ok").alert('close');
		}, 5000);
	}, false );


	//------------------------------------------------------------------------------
	// fix shifting menu items on hover
	//------------------------------------------------------------------------------
	$('.back-link').each(function () {
		//$(this).parent().width($(this).outerWidth() + 20);
		$(this).parent().height($(this).outerHeight() + 5);
	});

	//------------------------------------------------------------------------------
	// codedropz-upload-wrapper
	//------------------------------------------------------------------------------
	$('.codedropz-upload-wrapper').addClass('col-md-10 col-12');

	//------------------------------------------------------------------------------
	// Reference Image Slider
	//-----------------------------------------------------------------------------
	$('.reference-item-gallery').slick({
		centerMode: true,
		centerPadding: '0px',
		arrows: true,
		autoplay: false,
		autoplaySpeed: 4000,
		arrows: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		dots: false,
	  });

